// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-post-js": () => import("/opt/build/repo/src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("/opt/build/repo/src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-spozywac-miod-js": () => import("/opt/build/repo/src/pages/jak-spozywac-miod.js" /* webpackChunkName: "component---src-pages-jak-spozywac-miod-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mikroklimat-ula-js": () => import("/opt/build/repo/src/pages/mikroklimat-ula.js" /* webpackChunkName: "component---src-pages-mikroklimat-ula-js" */),
  "component---src-pages-miod-pszczeli-js": () => import("/opt/build/repo/src/pages/miod-pszczeli.js" /* webpackChunkName: "component---src-pages-miod-pszczeli-js" */),
  "component---src-pages-miody-odmianowe-js": () => import("/opt/build/repo/src/pages/miody-odmianowe.js" /* webpackChunkName: "component---src-pages-miody-odmianowe-js" */),
  "component---src-pages-o-nas-w-sieci-js": () => import("/opt/build/repo/src/pages/o-nas-w-sieci.js" /* webpackChunkName: "component---src-pages-o-nas-w-sieci-js" */),
  "component---src-pages-o-nas-js": () => import("/opt/build/repo/src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-js": () => import("/opt/build/repo/src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-produkty-pszczele-js": () => import("/opt/build/repo/src/pages/produkty-pszczele.js" /* webpackChunkName: "component---src-pages-produkty-pszczele-js" */)
}

